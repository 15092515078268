import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './Dropdown.css';

const DropdownSec = ({ header, state, stateChanger, downVals }) => {

    const handleChange = (event) => {
        stateChanger(event.target.value);
    };

    return (
        <div className='drop-down-container' >
            <h2>{header}</h2>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={state}
                    onChange={handleChange}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {downVals.map((val) => (
                        <MenuItem value={val}>{val}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

export default DropdownSec;
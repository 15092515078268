const Employees = {   
    "Incubation": [
        "Durga Prasad Gouda, Chief Executive Officer",
        "Deepshikha Sarkar, Incubation Manager",
        "Bikhyat Ku. Senapati, Assistant Manager",
        "Manoj Ku. Dandapat, Assistant Manager",
        "Roshan Panigrahi, Assistant Manager",
        "Adarsh Das, Investment Manager",
        "Seshadev Nayak, Assistant Manager",
        "Debasish, Tech Analyst",
    ],
    "CARE": [
        "Durga Prasad Gouda, Chief Executive Officer",
        "Bikhyat Ku. Senapati, Assistant Manager",
    ],
    "Finance and Accounts": [
        "Durga Prasad Gouda, Chief Executive Officer",
        "Bikhyat Ku. Senapati, Assistant Manager",
        "Malay Kumar Panigrahi, Finance Analyst",
    ],
    "Admin":[
        "Durga Prasad Gouda, Chief Executive Officer",
        "Bikhyat Ku. Senapati, Assistant Manager",
        "Debasish, Tech Analyst",
    ],
    "Innovation": [
        "Durga Prasad Gouda, Chief Executive Officer",
        "Manas Kumar Samantray, Chief Innovation Officer",
        "Shibani Mitra, Program Manager",
        "Nigam Jyoti Sahu, Assistant Manager",
        "Debasish, Tech Analyst",
    ],
    "Communications": [
        "Durga Prasad Gouda, Chief Executive Officer",
        "Shibani Mitra, Program Manager",
        "Manoj Ku. Dandapat, Assistant Manager",
    ],
    "Facilitation": [
        "Durga Prasad Gouda, Chief Executive Officer",
        "Shibani Mitra, Program Manager",
        "Roshan Panigrahi, Assistant Manager",
    ],
    "Investments": [
        "Durga Prasad Gouda, Chief Executive Officer",
        "Manoj Ku. Dandapat, Assistant Manager",
        "Adarsh Das, Investment Manager",
    ],
    "Projects": [
        "Durga Prasad Gouda, Chief Executive Officer",
        "Manoj Ku. Dandapat, Assistant Manager",
    ],
    "Portfolio": [
        "Durga Prasad Gouda, Chief Executive Officer",
        "Manoj Ku. Dandapat, Assistant Manager",
        "Jyoti Ranjan Sahoo, Research Analyst",
    ],
    "Consultancy": [
        "Durga Prasad Gouda, Chief Executive Officer",
        "Manoj Ku. Dandapat, Assistant Manager",
        "Jyoti Ranjan Sahoo, Research Analyst",
        "Monali Mohapatra, Legal Analyst"
    ],
    "Exception": [
        "Durga Prasad Gouda, Chief Executive Officer",
        "Manas Kumar Samantray, Chief Innovation Officer",
        "Deepshikha Sarkar, Incubation Manager",
        "Shibani Mitra, Program Manager",
        "Bikhyat Ku. Senapati, Assistant Manager",
        "Manoj Ku. Dandapat, Assistant Manager",
        "Roshan Panigrahi, Assistant Manager",
        "Adarsh Das, Investment Manager",
        "Seshadev Nayak, Assistant Manager",
        "Nigam Jyoti Sahu, Assistant Manager",
        "Debasish, Tech Analyst",
        "Jyoti Ranjan Sahoo, Research Analyst",
        "Malay Kumar Panigrahi, Finance Analyst",
        "Monali Mohapatra, Legal Analyst"
    ]
}


export default Employees;
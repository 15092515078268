import React from "react";

const divisions = {
  "Incubation": {
    "Database Schema": {
      "Startups": "",
      "Partners": "" 
    },
    "Website Schema": { 
      "https://www.aicnalanda.com/": "",
      "https://aicnitf.in/": ""
    } 
  },
  "CARE": {
    "Database Schema": {
      "Employees": "" 
    },
    "Website Schema": { 
      "https://www.aicnalanda.com/" : "",
      "https://aicnitf.in/" : ""
    }
  },
  "Finance and Accounts": {
    "Database Schema": "NA",
    "Website Schema": { 
      "https://www.aicnalanda.com/" : ""
    }
  },
  "Admin": {
    "Database Schema": "NA",
    "Website Schema": { 
      "https://www.aicnalanda.com/" : ""
    }
  },
  "Innovation": {
    "Database Schema": {
      "Students": "",
      "ATLs": "" ,
      "Mentors": "" 
    },
    "Website Schema": { 
      "https://www.aicnalanda.com/" : "",
      "https://fablab.aicnitf.in/" : ""
    }
  },
  "Communications": {
    "Database Schema": {
      "Startups": "",
      "Students": "",
      "Mentors": "",
      "Investors": "" 
    },
    "Website Schema": { 
      "https://www.aicnalanda.com/": ""
    } 
  },
  "Facilitation": {
    "Database Schema": {
      "Investors": "",
      "Startups": "",
      "Mentors": "",
      "Partners": "" 
    },
    "Website Schema": { 
      "https://www.aicnalanda.com/": "",
      "https://www.startconnect.in/": ""
    }
  },
  "Investments": {
    "Database Schema": {
      "Investors": "",
      "Partners": "",
      "Startups": "" 
    },
    "Website Schema": { 
      "https://www.aicnalanda.com/" : "",
      "https://www.startconnect.in/" : "" 
    }
  },
  "Projects": {
    "Database Schema": {
      "Partners": "" 
    },
    "Website Schema": { 
      "https://www.aicnalanda.com/" : "",
    }
  },
  "Portfolio": {
    "Database Schema": {
      "Startups": "" 
    },
    "Website Schema": "NA"
  },
  "Consultancy": {
    "Database Schema": {
      "Startups": "" 
    },
    "Website Schema": { 
      "https://www.aicnalanda.com/" : "",
      "https://ipcc.aicnitf.in/" : "",
    } 
  },
  "Exception": {
    "Database Schema": {
      "Emplyees": "",
      "Incubators": "",
      "Investors": "",
      "Mentors": "",
      "Startups": "",
      "Students": "",
      "ATLs": "",
      "Partners": "" 
    },
    "Website Schema": { 
      "https://www.aicnalanda.com/" : "",
      "https://aicnitf.in/" : "",
      "https://fablab.aicnitf.in/" : "",
      "https://www.startconnect.in/" : "",
      "https://starthire.aicnitf.in/" : "",
      "https://ipcc.aicnitf.in/" : "",
      "https://vritika.aicnitf.in/" : "",
      "https://otap.aicnitf.in/" : "",
      "https://www.osc.aicnitf.in/" : "",
      "https://www.mostartup.com/" : "",
    } 
  }
}

const divisionsBool = {
  "Incubation": {
    "Database Schema": {
      "Startups": false,
      "Partners": false
    },
    "Website Schema": {
      "https://www.aicnalanda.com/": false,
      "https://aicnitf.in/": false
    }
  },
  "CARE": {
    "Database Schema": {
      "Employees": false
    },
    "Website Schema": {
      "https://www.aicnalanda.com/": false,
      "https://aicnitf.in/": false
    }
  },
  "Finance and Accounts": {
    "Database Schema": false,
    "Website Schema": {
      "https://www.aicnalanda.com/": false
    }
  },
  "Admin": {
    "Database Schema": false,
    "Website Schema": {
      "https://www.aicnalanda.com/": false
    }
  },
  "Innovation": {
    "Database Schema": {
      "Students": false,
      "ATLs": false,
      "Mentors": false
    },
    "Website Schema": {
      "https://www.aicnalanda.com/": false,
      "https://fablab.aicnitf.in/": false
    }
  },
  "Communications": {
    "Database Schema": {
      "Startups": false,
      "Students": false,
      "Mentors": false,
      "Investors": false
    },
    "Website Schema": {
      "https://www.aicnalanda.com/": false
    }
  },
  "Facilitation": {
    "Database Schema": {
      "Investors": false,
      "Startups": false,
      "Mentors": false,
      "Partners": false
    },
    "Website Schema": {
      "https://www.aicnalanda.com/": false,
      "https://www.startconnect.in/": false
    }
  },
  "Investments": {
    "Database Schema": {
      "Investors": false,
      "Partners": false,
      "Startups": false
    },
    "Website Schema": {
      "https://www.aicnalanda.com/": false,
      "https://www.startconnect.in/": false
    }
  },
  "Projects": {
    "Database Schema": false,
    "Website Schema": false
  },
  "Portfolio": {
    "Database Schema": false,
    "Website Schema": false
  },
  "Consultancy": {
    "Database Schema": false,
    "Website Schema": false
  },
  "Projects": {
    "Database Schema": {
      "Partners": false 
    },
    "Website Schema": { 
      "https://www.aicnalanda.com/": false
    } 
  },
  "Portfolio": {
    "Database Schema": {
      "Startups": false 
    },
    "Website Schema": false 
  },
  "Consultancy": {
    "Database Schema": {
      "Startups": false 
    },
    "Website Schema": { 
      "https://www.aicnalanda.com/": false,
      "https://ipcc.aicnitf.in/": false,
    } 
  },
  "Exception": {
    "Database Schema": {
      "Emplyees": false,
      "Incubators": false,
      "Investors": false,
      "Mentors": false,
      "Startups": false,
      "Students": false,
      "ATLs": false,
      "Partners": false
    },
    "Website Schema": { 
      "https://www.aicnalanda.com/": false,
      "https://aicnitf.in/": false,
      "https://fablab.aicnitf.in/": false,
      "https://www.startconnect.in/": false,
      "https://starthire.aicnitf.in/": false,
      "https://ipcc.aicnitf.in/": false,
      "https://vritika.aicnitf.in/": false,
      "https://otap.aicnitf.in/": false,
      "https://www.osc.aicnitf.in/": false,
      "https://www.mostartup.com/": false,
    } 
  }
}

export { divisionsBool };

export default divisions;
import React, { useEffect, useState, useRef } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import "./ExpandedCL.css";
import Textarea from '@mui/joy/Textarea';
import Employees from '../content/employeeDetails';
import DropdownSec from './Dropdown';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { dbNotif, dbWeb } from '../content/notifContent';
import Button from '@mui/material/Button';
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import { useReactToPrint } from 'react-to-print';
import { v4 as uuidv4 } from "uuid";


const ExpandedCL = ({ divisions, division, divisionBool, setDivisionBool, contentRef }) => {
    const [mainCL, setMainCL] = useState({
        "Database Schema": false,
        "Website Schema": false
    })
    const [submitState, setSubmitState] = useState(false);
    const [finalData, setFinalData] = useState({});
    const [content, setContent] = useState(divisions[division])
    const [nestCL, setNestCL] = useState({})
    const [Employ, setEmploy] = useState("")
    const [divisionsEdit, setDivisionsEdit] = useState(divisions);
    const [test, SetTest] = useState("something")

    const containsTrueValue = (nestedObject) => {
        if (typeof nestedObject === 'object' && nestedObject !== null) {
            for (const key in nestedObject) {
                if (typeof nestedObject[key] === 'object') {
                    if (containsTrueValue(nestedObject[key])) {
                        return true;
                    }
                } else if (nestedObject[key] === true) {
                    return true;
                }
            }
        }
        return false;
    };

    const submitOnclick = () => {
        if (mainCL["Database Schema"] === false && mainCL["Website Schema"] === false) {
            alert("Please select atleast one schema to continue !")
        }
        else if (Employ == "") {
            alert("Please select employee identity to continue !")
        }
        else if (containsTrueValue(divisionBool[division]["Database Schema"]) === false
            && containsTrueValue(divisionBool[division]["Website Schema"]) === false
        ) {
            alert("Please select atleast one sub schema to continue !")
        }
        else {
            const dateObject = new Date(); // Create a new Date object for the current date and time
            const options = {
                year: 'numeric', // full numeric value of the year (e.g., 2024)
                month: '2-digit', // numeric month (01 through 12)
                day: '2-digit', // numeric day of the month (01 through 31)
                hour: '2-digit', // numeric hour (00 through 23)
                minute: '2-digit', // numeric minute (00 through 59)
                second: '2-digit', // numeric second (00 through 59)\
                hour12: true, // Use 12-hour format
            };

            const formattedDateTime = dateObject.toLocaleString('en-GB', options); // Format date and time according to specified options
            formattedDateTime.replace(',', ''); // Remove the comma added by default
            setFinalData({
                ...finalData,
                uuid: uuidv4(),
                timedelta: formattedDateTime
            })
            setSubmitState(true)
        }
    }

    useEffect(() => {
        if (submitState) {
            if (handlePrint) {
                handlePrint();
                // alert("The session is completed. Do you want to make another response ?")
                setSubmitState(false);
            }
        }
    }, [submitState])



    const handlePrint = useReactToPrint({
        content: () => contentRef.current,
        documentTitle: finalData?.uuid,
    });

    // const submitOnClick = () => {
    //     var doc = new jsPDF();
    //     const contentString = ReactDOMServer.renderToStaticMarkup(Returnable());
    //     // console.log("content>>", contentString)
    //     // console.log("html content>>", <Returnable/>)
    //     // const contentElem = contentRef.current;
    //     doc.html(contentString, {
    //         callback: function(doc) {
    //             doc.save("sample.pdf")
    //         },
    //         x: 10,
    //         y: 10
    //     })
    // }

    useEffect(() => {
        setContent(divisions[division]);
        setEmploy("");
    }, [division, divisions])

    return (
        <>
            <div className="expanded-check-container">
                <FormGroup>
                    {submitState ? <>
                        <div className="outro-data">
                            <p>Session ID: {finalData?.uuid}</p>
                            <p>Time Stamp: {finalData?.timedelta}</p>
                        </div>
                    </>
                        : ""
                    }
                    {Object.keys(content).map((item, index) => (
                        <>
                            <FormControlLabel {...content[item] == "NA" ? { disabled: true } : {}} key={index}
                                control={<Checkbox {...mainCL[item] ? { checked: true } : {}} />}
                                label={item}
                                onChange={(e) => {
                                    setMainCL({
                                        ...mainCL,
                                        [item]: e.target.checked
                                    })
                                }}
                            />
                            <>
                                {item == "Database Schema" && content[item] !== "NA" ?
                                    <>
                                        <div className="inner-cl">
                                            <p>*{dbNotif}</p>
                                            <FormGroup>
                                                {Object.keys(content[item]).map((subItem, subIndex) => (
                                                    <>
                                                        <FormControlLabel
                                                            control={<Checkbox {...divisionBool[division]["Database Schema"][subItem] ? { checked: true } : { checked: false }} />}
                                                            {...mainCL[item] === false ? { disabled: true } : {}}
                                                            label={subItem}
                                                            onChange={(e) => {
                                                                setDivisionBool({
                                                                    ...divisionBool,
                                                                    [division]: {
                                                                        ...divisionBool[division],
                                                                        "Database Schema": {
                                                                            ...divisionBool[division]["Database Schema"],
                                                                            [subItem]: e.target.checked
                                                                        }
                                                                    }
                                                                })
                                                            }}
                                                        />

                                                        <div className="input-box-container">
                                                            {divisionBool[division]["Database Schema"][subItem] ?
                                                                <Textarea placeholder="Add googe sheet URL and/or details about the data here !"
                                                                    value={divisionsEdit[division][item][subItem]}
                                                                    onChange={
                                                                        (e) => {
                                                                            setDivisionsEdit({
                                                                                ...divisionsEdit,
                                                                                [division]: {
                                                                                    ...divisionsEdit[division],
                                                                                    [item]: {
                                                                                        ...divisionsEdit[division][item],
                                                                                        [subItem]: e.target.value
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }
                                                                />
                                                                : <></>}
                                                        </div>
                                                    </>
                                                ))}
                                            </FormGroup>
                                        </div>
                                    </>
                                    : ""}
                            </>
                            <>
                                {item == "Website Schema" && content[item] !== "NA" ?
                                    <>
                                        <div className="inner-cl">
                                            <p>*{dbWeb}</p>
                                            <FormGroup>
                                                {Object.keys(content[item]).map((subItem, subIndex) => (
                                                    <>
                                                        <React.Fragment key={subIndex}>
                                                            <FormControlLabel key={subIndex}
                                                                control={<Checkbox {...divisionBool[division]["Website Schema"][subItem] ? { checked: true } : { checked: false }} />}
                                                                label={subItem}
                                                                {...mainCL[item] === false ? { disabled: true } : {}}
                                                                onChange={(e) => {
                                                                    setDivisionBool({
                                                                        ...divisionBool,
                                                                        [division]: {
                                                                            ...divisionBool[division],
                                                                            "Website Schema": {
                                                                                ...divisionBool[division]["Website Schema"],
                                                                                [subItem]: e.target.checked
                                                                            }
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                            <div className="input-box-container">
                                                                {divisionBool[division]["Website Schema"][subItem] ?
                                                                    <Textarea placeholder="Add googe sheet URL or Cloud Storage URL for data sources and/or details about the data here !"
                                                                        value={divisionsEdit[division][item][subItem]}
                                                                        onChange={
                                                                            (e) => {
                                                                                setDivisionsEdit({
                                                                                    ...divisionsEdit,
                                                                                    [division]: {
                                                                                        ...divisionsEdit[division],
                                                                                        [item]: {
                                                                                            ...divisionsEdit[division][item],
                                                                                            [subItem]: e.target.value
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        }
                                                                    />
                                                                    : <></>}
                                                            </div >
                                                        </React.Fragment>
                                                    </>
                                                ))}
                                            </FormGroup>
                                        </div>
                                    </>
                                    : ""}
                            </>
                        </>
                    ))}
                    <div className="add-detail-con-div">
                        <h3>Additional Details</h3>
                        <Textarea minRows="5" placeholder="Share your remarks or special notation for update in website or database !" />
                    </div>
                    <div className="bottom-sec">
                        <h3>Prepared By</h3>
                        <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={Employ}
                                onChange={(e) => setEmploy(e.target.value)}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {Employees[division].map((val) => (
                                    <MenuItem value={val}>{val}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </FormGroup>
                {submitState ? <>
                </> :
                    <div className="submit-btn-con">
                        <Button variant="contained"
                            onClick={() => submitOnclick()}
                        >Submit</Button>
                    </div>
                }

            </div>
        </>
    )
}

export default ExpandedCL;
import logo from './logo.svg';
import './App.css';

import * as React from 'react';
import DropdownSec from './components/Dropdown';
import FormControl from '@mui/material/FormControl';
import divisions, {divisionsBool} from './content/divisionData';
import ExpandedCL from './components/ExpandedCL';
import { useRef } from 'react';

function App() {
  const [division, setDivision] = React.useState('None');
  const [divBoolEdit, setDivBoolEdit] = React.useState(divisionsBool);
  const mainContentRef = useRef(null);

  return (
    <div className="App" ref={mainContentRef}>
      <header className="App-header">
        <h1>AIC-NITF, Digital Resource Management Schema</h1>
        {/* <div className='download_btn_container'>
          <img src='icons/download_icon.png' />
        </div> */}
      </header>
      <div className='main-container'>
        <DropdownSec header='Departments' state={division} stateChanger={setDivision} downVals={Object.keys(divisions)} />
        <div className='nest-container'>
          {division == "" ? "" : <>
            {divisions[division] &&
              <ExpandedCL divisions={divisions} division={division} divisionBool={divBoolEdit} setDivisionBool={setDivBoolEdit} contentRef={mainContentRef}/>
            }
          </>}
        </div>
      </div>
    </div>
  );
}

export default App;
